import Vue from 'vue'
import router from "./Routes";
import App from './App.vue';
import { Button, Loading } from 'element-ui';
import axios from "axios";
import 'element-ui/lib/theme-chalk/index.css';

Vue.config.productionTip = false
Vue.use(Button);
Vue.use(Loading);
Vue.prototype.$http = axios;

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')