<template>
  <div id="app">
    <header>
      <img src="./assets/logo.svg" v-if="!isgeo || isgeo === 'ge'">
      <img src="./assets/logo-en.svg" v-if="isgeo === 'en'">
    </header>
    <div class="content">
      <router-view @strings="getStrings" @lang="getLang" @footer="footerIsShown"></router-view>
    </div>
    <footer>
      <template v-if="strings && showFooter">
        {{ strings[isgeo].footer }}
      </template>
    </footer>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  },
  data() {
    return {
      birthday : '',
      isgeo : false,
      owner : '',
      strings : false,
      showFooter: false,
      result : ''
    };
  },
  
  methods: {
    getStrings(data) {
      this.strings = data;
    },
    
    getLang(lang) {
      this.isgeo = lang;
    },

    footerIsShown() {
      this.showFooter = !this.showFooter;
    }
  }
}
</script>

<style>
@font-face {
  font-family: 'ARDINus';
  src: url('./assets/fonts/ARDI Nus Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./assets/fonts/ARDI Nus Bold.ttf') format('truetype'); /* Safari, Android, iOS */
  font-style: normal;
  font-weight: bold;
  text-rendering: optimizeLegibility;
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: 'ARDINus';
  background: #007b62;
}

* { box-sizing: border-box; }

body,
#app {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: calc(100vh - 60px);
  height: 100%;
}

#app {
  width: 100%;
  padding: 30px 30px 100px;
}

.hide {
  display: none !important;
}

.show {
  display: block !important;
}

header img {
  width: 120px;
}

.content .text {
  font-size: 30px;
  font-weight: bold;
  color: #fff;
  text-align: center;
}

.notification {
  font-size: 30px;
  font-weight: bold;
  color: #fff;
  text-align: center;
}

.buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 50px;
}

.buttons .el-button {
  padding: 15px 40px;
  font-size: 20px;
  font-family: 'ARDINus';
}

.buttons .el-button:hover,
.buttons .el-button:focus,
.buttons .el-button:active {
  color: #fff;
  border-color: #fff;
  background: rgba(0, 123, 98, .8);
}

.el-button.yes {
  color: #007b62;
}

.el-button.no {
  color: #ff0000;
}

footer {
  font-size: 20px;
  color: #fff;
}

@media screen and (max-width: 700px) {
  .notification,  
  .content .text {
    font-size: 20px;
  }
  
  footer,
  .buttons .el-button {
    font-size: 15px;
  }
}

</style>
