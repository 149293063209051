import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const Verify = () => import('./components/Verify');
const NotFound = () => import('./components/Error');

const routes = [
    { path: '/:id', component: Verify },
    { path: "*", component: NotFound }
]

const router = new Router({
    mode: 'history',
    routes
})

export default router;